import * as React from 'react';
import {Link} from 'react-router-dom';

const Footer = () => (
  <div className="row colw-12 padding-12 pin-bottom p-absolute">
    <div className="col-3 footer-block padding-12">
      Copyright
      <br />
    </div>
    <div className="col-5 footer-block padding-12">
      <Link to="/about">About</Link>
      <br />
      <Link to="/work">Work</Link>
      <br />
      <Link to="/contact">Contact</Link>
    </div>
    <div className="col col-4 footer-block padding-12">
      mngyuan
      <br />
      梦远
    </div>
  </div>
);

export default Footer;
