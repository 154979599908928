// @flow
//
import * as React from 'react';
import {Switch, Route, Link, withRouter} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

import About from './About';
import Work from './Work';
import Contact from './Contact';
import Footer from './Footer';

const ROWS = 50;
const COLS = 60;
const NUM_PARTICLES = ROWS * COLS,
  THICKNESS = Math.pow(256, 2),
  SPACING = 30,
  MARGIN = 0,
  COLOR = 220,
  DRAG = 0.95,
  EASE = 0.25,
  DAMPENING = 10;
let container,
  particle,
  canvas,
  list,
  ctx,
  tog,
  man,
  dx,
  dy,
  mx,
  my,
  d,
  t,
  f,
  a,
  b,
  i,
  w,
  h,
  p;

particle = {
  vx: 0,
  vy: 0,
  x: 0,
  y: 0,
};

function init() {
  container = document.getElementById('container');
  canvas = document.createElement('canvas');

  ctx = canvas.getContext('2d');
  man = false;
  tog = true;

  list = [];

  w = canvas.width = COLS * SPACING + MARGIN * 2;
  h = canvas.height = ROWS * SPACING + MARGIN * 2;

  for (i = 0; i < NUM_PARTICLES; i++) {
    p = Object.create(particle);
    p.x = p.ox = MARGIN + SPACING * (i % COLS);
    p.y = p.oy = MARGIN + SPACING * Math.floor(i / COLS);

    list[i] = p;
  }

  document.addEventListener('mousemove', function(e) {
    const bounds = container.getBoundingClientRect();
    mx = e.clientX - bounds.left;
    my = e.clientY - bounds.top;
    man = true;
  });

  container.appendChild(canvas);
}

function step() {
  if ((tog = !tog)) {
    if (!man) {
      t = +new Date() * 0.001;
      mx = w * 0.5 + Math.cos(t * 2.1) * Math.cos(t * 0.9) * w * 0.45;
      my = h * 0.5 + Math.sin(t * 3.2) * Math.tan(Math.sin(t * 0.8)) * h * 0.45;
    }

    for (i = 0; i < NUM_PARTICLES; i++) {
      p = list[i];

      d = (dx = mx - p.x) * dx + (dy = my - p.y) * dy;
      f = -THICKNESS / d / DAMPENING;

      if (d < THICKNESS) {
        t = Math.atan2(dy, dx);
        p.vx += f * Math.cos(t);
        p.vy += f * Math.sin(t);
      }

      p.x += (p.vx *= DRAG) + (p.ox - p.x) * EASE;
      p.y += (p.vy *= DRAG) + (p.oy - p.y) * EASE;

      if (Math.abs(p.x - p.ox) < 0.5) {
        p.x = p.ox;
      }
      if (Math.abs(p.y - p.oy) < 0.5) {
        p.y = p.oy;
      }
    }
  } else {
    b = (a = ctx.createImageData(w, h)).data;

    for (i = 0; i < NUM_PARTICLES; i++) {
      let n;
      p = list[i];
      b[(n = (~~p.x + ~~p.y * w) * 4)] = b[n + 1] = b[n + 2] = 0;
      b[n + 3] = 255;
    }

    ctx.putImageData(a, 0, 0);
  }

  requestAnimationFrame(step);
}

class ChangingBanner extends React.PureComponent {
  interval = null;
  wordPairs = [
    ['you', 'your'],
    ['him', 'his'],
    ['her', 'her'],
    ['me', 'my'],
    ['them', 'their'],
    ['everyone', "y'alls"],
  ];

  state = {
    curIndex: 0,
  };

  componentDidMount() {
    this.interval = setInterval(
      () =>
        this.setState(prevState => ({
          curIndex: (prevState.curIndex + 1) % this.wordPairs.length,
        })),
      1000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <>
        <div className="banner on-load-fade-in">
          Designed with {this.wordPairs[this.state.curIndex][0]} in mind
          <span className="x-large">.</span>
        </div>
        <br />
        <p className="subtitle">
          and {this.wordPairs[this.state.curIndex][1]} dogs, too.
        </p>
      </>
    );
  }
}

class Landing extends React.PureComponent {
  observer = new IntersectionObserver(
    (entries, self) =>
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          self.unobserve(entry.target);
        }
      }),
    {threshold: 0.2},
  );

  componentDidMount() {
    const boxes = document.querySelectorAll('.on-load-fade-in');
    console.log(boxes);
    boxes.forEach(box => this.observer.observe(box));
  }

  render() {
    return (
      <>
        <div className="row row-10 a-center j-center f-shrink-no">
          <div className="col marginc-t-240 padding-12 col-9 row-6">
            <div className="banner on-load-fade-in">
              Time <br />
              for real change<span className="x-large">.</span>
            </div>
            <br />
            <p className="subtitle">Make a difference, today.</p>
          </div>
        </div>
        <div className="row row-10 a-center j-center f-shrink-no">
          <div className="col margin-t-96 padding-12 col-9 text-a-right">
            <div className="banner on-load-fade-in">
              Made to work<span className="x-large">.</span>
            </div>
            <br />
            <p className="subtitle">
              Out of the box, with an instructions list so simple your fish
              could follow along.
            </p>
          </div>
        </div>
        <div className="row row-10 a-center j-center f-shrink-no">
          <div className="col marginc-t-240 padding-12 col-9 row-6">
            <ChangingBanner />
          </div>
        </div>
        <div className="row row-12 a-center j-center f-shrink-no">
          <div className="col margin-t-96 padding-12 col-9 text-a-center a-center">
            <div className="banner on-load-fade-in">
              Don't wait<span className="x-large">.</span>
            </div>
            <br />
            <div className="pill button">Get Started</div>
          </div>
        </div>
        <div className="row colw-12 padding-12">
          <div className="col-3 footer-block padding-12">
            Copyright
            <br />
          </div>
          <div className="col-5 footer-block padding-12">
            <Link to="/about">About</Link>
            <br />
            <Link to="/work">Work</Link>
            <br />
            <Link to="/contact">Contact</Link>
          </div>
          <div className="col col-4 footer-block padding-12">
            mngyuan
            <br />
            梦远
          </div>
        </div>
      </>
    );
  }
}

class App extends React.PureComponent {
  componentDidMount() {
    init();
    step();
  }

  render() {
    return (
      <div className="col col-12 row-12 o-scroll">
        <TransitionGroup className="p-relative col-12 row-12">
          <CSSTransition
            key={this.props.location.key}
            timeout={{enter: 300, exit: 300}}
            classNames="fade"
          >
            <section className="p-absolute colw-12 row-12">
              <Switch location={this.props.location}>
                <Route path="/" exact component={Landing} />
                <Route path="/about" component={About} />
                <Route path="/work" component={Work} />
                <Route path="/contact" component={Contact} />
              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>
        <div id="container" className="p-fixed colw-12 row-12"></div>
        <div className="row p-fixed pin-top colw-12 a-center j-between padding-12">
          <Link to="/">
            <div className="nameplate padding-12">OWW</div>
          </Link>
          <nav className="row text-a-center">
            <Link to="/about" className="col-4-s padding-12">
              About
            </Link>
            <Link to="/work" className="col-4-s padding-12">
              Work
            </Link>
            <Link to="/contact" className="col-4-s padding-12">
              Contact
            </Link>
          </nav>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
