// @flow
//
import * as React from 'react';
import Footer from './Footer';

const About = () => (
  <div className="p-relative page-container">
    <div className="col margin-h-col-2 col-8 margin-t-240">
      <h1>One Website a Week.</h1>
      <br />
      <br />
      <p>I've built one website a week for the last 10 or so weeks.</p>
    </div>
    <Footer />
  </div>
);

export default About;
