// @flow
//
import * as React from 'react';
import Footer from './Footer';

const Contact = () => (
  <>
    <div className="col margin-h-col-2 col-8 margin-t-240">
      <h1>Contact Us</h1>
      <br />
      We'd love to talk about your next endeavor - with us. Don't be a stranger!
      <br />
      <br />
      <br />
      <form>
        <label>Full Name</label>
        <br />
        <input type="text" />
        <br />
        <label>Your Email</label>
        <br />
        <input type="text" />
        <br />
        <label>Your Mother's Maiden Name</label>
        <br />
        <input type="text" />
      </form>
    </div>
    <Footer />
  </>
);

export default Contact;
