// @flow
//
import * as React from 'react';
import Footer from './Footer';

const Work = () => (
  <>
    <div className="col margin-h-col-2 col-8 margin-t-240">
      <table>
        <thead>
          <tr>
            <th>Week</th>
            <th>Technologies Used/Learned</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mngyuan.com/oww1"
              >
                OWW1
              </a>
            </td>
            <td>Intersection Observer, 12 col Flex Layout</td>
          </tr>
          <tr>
            <td>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mngyuan.com/oww2"
              >
                OWW2
              </a>
            </td>
            <td>Card stack layout, sticky image scrolling</td>
          </tr>
          <tr>
            <td>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mngyuan.com/oww3"
              >
                OWW3
              </a>
            </td>
            <td>Tone.js, knobs, sliders</td>
          </tr>
          <tr>
            <td>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mngyuan.com/oww4"
              >
                OWW4
              </a>
            </td>
            <td>Scrolljacking, variable scroll speeds</td>
          </tr>
          <tr>
            <td>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mngyuan.com/oww5"
              >
                OWW5
              </a>
            </td>
            <td>Drawer layout, media overlay</td>
          </tr>
          <tr>
            <td>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mngyuan.com/oww6"
              >
                OWW6
              </a>
            </td>
            <td>Seeded random generation, CSS Grid, responsive text sizing</td>
          </tr>
          <tr>
            <td>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mngyuan.com/oww7"
              >
                OWW7
              </a>
            </td>
            <td>React CSS transitions, HTML5 Canvas</td>
          </tr>
          <tr>
            <td>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mngyuan.com/oww8"
              >
                OWW8
              </a>
            </td>
            <td>HTML5 Canvas, video streaming</td>
          </tr>
          <tr>
            <td>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mngyuan.com/oww9"
              >
                OWW9
              </a>
            </td>
            <td>CSS Grid</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Footer />
  </>
);

export default Work;
